<template>
  <div>
    <v-container fluid>
      <v-card>    
        <v-toolbar flat color="white">
            <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" max-width="300px" prepend-inner-icon="search" :label="constantes.BOTAO_FILTRAR_TRANSPORTADOR"></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="novo" data-cy="novo">{{constantes.BOTAO_NOVO_TRANSPORTADOR}}</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="transportadores" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td class="text-no-wrap">{{ props.item.cnpj_cpf | formatarCnpj }}</td>
              <td>{{ props.item.inscricao_rg }}</td>
              <td>{{ props.item.razao_nome }}</td>
              <td>{{ props.item.cidade }}</td>
              <td>{{ props.item.estado }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="remover(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'CNPJ / CPF', value: 'cnpj_cpf' },
          { text: 'Inscrição / RG', value: 'inscricao_rg' },
          { text: 'Razão Social / Nome', value: 'razao_nome' },
          { text: 'Cidade', value: 'cidade' },
          { text: 'Estado', value: 'estado' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    watch: {
      options: {
        handler () {
          this.carregar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('transportador', {
        transportadores: 'transportadores',
        carregando: 'carregando',
        total: 'totalTransportadores'
      })
    },

    methods: {

      novo(){
        this.$store.commit('transportador/novo');
        this.$router.push({name: 'formularioTransportadorNovo'});
      },

      editar(transportador){
        this.$store.commit('transportador/editar', transportador);
        this.$router.push({name: 'formularioTransportadorEditar'});
      },

      carregar(){
        this.$store.dispatch('transportador/carregar', this.options);
      },

      limparBusca(){
        this.carregar();
      },

      filtrar(event){

        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('transportador/carregar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300)
      },

      remover(transportador){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('transportador/remover', transportador);
          }
        });
      }
    }
  }
</script>